import React from 'react'
export const auto_sell = {
  path: (
    <g fill="none">
      <path
        d="M14.5 12H21M21 12L18 15M21 12L18 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12H8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6647 5.5C13.3516 4.55596 11.7407 4 10 4C5.58172 4 2 7.58172 2 12C2 16.4183 5.58172 20 10 20C11.7407 20 13.3516 19.444 14.6647 18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
