import React from 'react'
export const selectBorrowActive = {
  path: (
    <g fill="none" key="S1_-a9loW">
      <path
        key="PRa5Ml3yV"
        d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
        fill="#EEF1F1"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        key="rrLsYUjhds"
        d="M18 7C11.9249 7 7 11.9249 7 18C7 20.9034 8.12351 23.5421 9.96139 25.5088C10.3385 25.9123 10.3171 26.5451 9.91355 26.9222C9.51004 27.2993 8.87724 27.2779 8.50015 26.8743C6.32982 24.5519 5 21.4302 5 18C5 10.8203 10.8203 5 18 5C18.5523 5 19 5.44772 19 6C19 6.55228 18.5523 7 18 7ZM25.0898 8.26605C25.4511 7.84833 26.0826 7.80258 26.5003 8.16386C29.2547 10.5461 31 14.0698 31 18C31 25.1797 25.1797 31 18 31C17.4477 31 17 30.5523 17 30C17 29.4477 17.4477 29 18 29C24.0751 29 29 24.0751 29 18C29 14.6748 27.5258 11.6951 25.192 9.67656C24.7743 9.31528 24.7285 8.68377 25.0898 8.26605Z"
        fill="#596F78"
      />
      <path
        key="ECtNi0sDLD"
        d="M20.4068 5.589C20.6938 5.78784 20.6938 6.21216 20.4068 6.411L18.0347 8.05439C17.7031 8.28413 17.25 8.0468 17.25 7.64339L17.25 4.35661C17.25 3.9532 17.7031 3.71587 18.0347 3.94561L20.4068 5.589Z"
        fill="#596F78"
      />
      <path
        key="M5-dl1skV-"
        d="M14.5932 29.589C14.3062 29.7878 14.3062 30.2122 14.5932 30.411L16.9653 32.0544C17.2969 32.2841 17.75 32.0468 17.75 31.6434L17.75 28.3566C17.75 27.9532 17.2969 27.7159 16.9653 27.9456L14.5932 29.589Z"
        fill="#596F78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        key="ae7n3jv8WC"
        d="M20 15H22C22 12.7909 20.2091 11 18 11C15.7909 11 14 12.7909 14 15H16C16 13.8954 16.8954 13 18 13C19.1046 13 20 13.8954 20 15ZM12 18C12 16.8954 12.8954 16 14 16H22C23.1046 16 24 16.8954 24 18V23C24 24.1046 23.1046 25 22 25H14C12.8954 25 12 24.1046 12 23V18ZM19 19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19C17 18.4477 17.4477 18 18 18C18.5523 18 19 18.4477 19 19Z"
        fill="#596F78"
      />
    </g>
  ),
  viewBox: '0 0 36 36',
}
