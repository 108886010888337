import React from 'react'
export const take_profit = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7574 12.2426C11.3668 11.8521 11.3668 11.2189 11.7574 10.8284L16.4459 6.13988L16.2929 5.46444L17.6804 2.55916C17.8598 2.18359 18.3927 2.17877 18.5788 2.55103L19.0882 3.56979C19.4627 3.38248 19.9303 3.445 20.2426 3.75736C20.555 4.06971 20.6175 4.53729 20.4302 4.91178L21.4489 5.42114C21.8212 5.60727 21.8164 6.14017 21.4408 6.31954L18.5355 7.70708L17.8604 7.55414L17.7851 7.62951L17.785 7.62943L17.8603 7.55412L17.8601 7.55408L15.9909 9.42328L14.8942 10.5201L13.1716 12.2426C12.7811 12.6332 12.1479 12.6332 11.7574 12.2426ZM13.6333 7.5383C13.124 7.3518 12.5738 7.25002 11.9999 7.25002C9.37656 7.25002 7.24991 9.37666 7.24991 12C7.24991 14.6234 9.37656 16.75 11.9999 16.75C14.6233 16.75 16.7499 14.6234 16.7499 12C16.7499 11.4261 16.6481 10.876 16.4617 10.3668L15.2259 11.6026C15.2417 11.7328 15.2499 11.8655 15.2499 12C15.2499 13.7949 13.7948 15.25 11.9999 15.25C10.205 15.25 8.74991 13.7949 8.74991 12C8.74991 10.2051 10.205 8.75002 11.9999 8.75002C12.1345 8.75002 12.2672 8.7582 12.3975 8.77409L13.6333 7.5383ZM19.3833 7.30237L19.3833 7.30231L18.5357 7.7071L18.5359 7.70713L19.3833 7.30237ZM12 3.24996C13.371 3.24996 14.6684 3.5653 15.8236 4.12741L15.2412 5.34682L15.2837 5.53454C14.2979 5.03282 13.1819 4.74996 12 4.74996C7.99589 4.74996 4.74996 7.9959 4.74996 12C4.74996 16.004 7.99589 19.25 12 19.25C16.004 19.25 19.25 16.004 19.25 12C19.25 10.8181 18.9672 9.70232 18.4656 8.71655L18.6535 8.75912L19.8728 8.17683C20.4347 9.33188 20.75 10.6291 20.75 12C20.75 16.8325 16.8324 20.75 12 20.75C7.16746 20.75 3.24996 16.8325 3.24996 12C3.24996 7.16747 7.16746 3.24996 12 3.24996Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
}
